<template>
  <div class="library">
    <div class="box">
      <div class="left leftFlex">
        <div class="sidebar">
          <el-menu :default-active="iActive">
            <el-menu-item index="0" @click="$router.push('/infoCompileIndex')">
              <img
                :src="require('assets/images/goldenSentence/back.png')"
                alt=""
              />
              返回
            </el-menu-item>
            <el-menu-item
              index="4"
              @click="
                $router.push({
                  name: 'MyAttention',
                })
              "
            >
              我的关注
            </el-menu-item>
            <el-menu-item
              index="1"
              @click="
                $router.push({
                  name: 'WorksRank',
                })
              "
            >
              作品榜
            </el-menu-item>
            <el-menu-item
              index="2"
              @click="
                $router.push({
                  name: 'AuthorRank',
                })
              "
            >
              作者榜
            </el-menu-item>
            <el-menu-item
              index="3"
              @click.native="$router.push('/copyrightIncome')"
            >
              版权作品投稿
            </el-menu-item>
          </el-menu>
        </div>
        <div class="main">
          <router-view ref="refRouter"></router-view>
        </div>
      </div>
      <div class="right recommendRightList">
        <recommend />
      </div>
    </div>
    <div class="commonFooter_box">
      <commonFooter />
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import commonFooter from "components/footer/commonFooter.vue";
import recommend from "components/recommend/recommend.vue";
export default {
  name: "library",
  //import引入的组件需要注入到对象中才能使用
  // components: {},
  data() {
    //这里存放数据
    return {
      iActive: "0",
    };
  },
  created() {
    // console.log(this.$route);
    // this.iActive = this.$route.params.index;
  },
  components: {
    commonFooter,
    recommend,
  },
  mounted() {
    this.$nextTick(() => {
      this.iActive = this.$refs.refRouter.$route.params.index;
    });
  },
};
</script>
<style lang="scss" scoped>
.library {
  width: 100%;
  position: absolute;
  left: 0;
  margin-top: -25px;
  padding: 25px 0 0 0;
  background-color: #f9f9f9;
  .box {
    display: flex;
    position: relative;
    width: 1338px;
    box-sizing: border-box;
    margin: 0 auto;
    .left {
      width: 936px;
      margin-right: 20px;
      background-color: #fff;
      display: flex;
      position: relative;
    }
    .right {
      background-color: #fff;
      flex: 1;
    }
    //  侧边栏
    .sidebar {
      min-width: 150px;
      overflow-y: auto;
      height: calc(100vh - 51px);
      border-right: 1px solid #f7f7f7;

      .el-menu {
        border-right: 0px;
        .el-menu-item {
          display: flex;
          align-items: center;
          height: 34px;
          line-height: 34px;
          font-size: 14px;
          -webkit-transition: none;
          transition: none;
          &:hover {
            color: #ff6900;
            border-right: #ff6900 4px solid;
          }

          img {
            margin-right: 5px;
          }
        }

        .el-menu-item.is-active {
          border-right: #ff6900 4px solid;
          background-color: #ffbf6914;
        }
      }
    }
    .main {
      flex: 1;
    }
  }
}
</style>
